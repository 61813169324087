// app/javascript/controllers/map_controller.js
import { Controller } from "@hotwired/stimulus"
import { Loader } from "@googlemaps/js-api-loader"

export default class extends Controller {
  static values = { 
    apiKey: String,
    location: String,
    countryName: String
  }

  connect() {
    this.loadMap()
  }

  loadMap() {
    const loader = new Loader({
      apiKey: this.apiKeyValue,
      version: "weekly",
      libraries: ["places"]
    });

    loader.load().then(() => {
      const map = new google.maps.Map(this.element, {
        zoom: 8,
        center: {lat: -34.397, lng: 150.644}
      });
      this.geocodeAddress(map);
    });
  }

  geocodeAddress(resultsMap) {
    const geocoder = new google.maps.Geocoder();
    const address = encodeURIComponent(this.locationValue + ' ' + this.countryNameValue);

    geocoder.geocode({ 'address': address }, (results, status) => {
      if (status === 'OK') {
        resultsMap.setCenter(results[0].geometry.location);
        new google.maps.Marker({
          map: resultsMap,
          position: results[0].geometry.location
        });
      } else {
        console.error('Geocode was not successful for the following reason: ' + status);
      }
    });
  }
}

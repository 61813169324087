import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    endDate: String,
    startingDate: String,
    auctionId: Number,
    propertyId: Number
  }

  connect() {
    this.updateCountdown();
    this.interval = setInterval(() => this.updateCountdown(), 1000);
    this.element.addEventListener("auctionStarting", this.handleAuctionStarting.bind(this));
    this.element.addEventListener("auctionEnded", this.handleAuctionEnded.bind(this));
  }

  handleAuctionStarting() {
    this.updateMenuActions();
    this.toggleVisibility(`pre_auction_element_${this.auctionIdValue}`, false);
    this.toggleVisibility(`starting_auction_element_${this.auctionIdValue}`, true);
  }

  handleAuctionEnded() {
    this.updateMenuActions();
    this.toggleVisibility(`starting_auction_element_${this.auctionIdValue}`, false);
    this.toggleVisibility(`ending_auction_element_${this.auctionIdValue}`, true);
    this.updateMessage("The auction has ended", "end");
  }

  updateMenuActions() {
    fetch(`/properties/${this.propertyIdValue}/menu_actions`)
      .then(response => response.text())
      .then(html => {
        document.getElementById("menu_actions_container").innerHTML = html;
      })
      .catch(error => console.error("Error updating menu actions:", error));
  }


  toggleVisibility(className, show) {
    const elements = document.querySelectorAll(`.${className}`);
    elements.forEach(element => {
      element.style.display = show ? '' : 'none';
    });
  }

  updateCountdown() {
    const now = new Date();
    const endDate = new Date(this.endDateValue);
    const startingDate = new Date(this.startingDateValue);

    if (now < startingDate) {
      this.displayCountdown(startingDate - now, "Auction will start in:", "start");
    } else if (now >= startingDate && now < endDate) {
      if (!this.startEventDispatched) {
        this.element.dispatchEvent(new CustomEvent("auctionStarting"));
        this.startEventDispatched = true; // Asegura que el evento se dispare una sola vez
      }
      this.displayCountdown(endDate - now, "Time remaining:", "end");
    } else if (now >= endDate) {
      if (!this.endEventDispatched) {
        this.element.dispatchEvent(new CustomEvent("auctionEnded"));
        this.endEventDispatched = true; // Asegura que el evento se dispare una sola vez
      }
      this.updateMessage("The auction has ended", "end");
    }
  }
  displayCountdown(distance, message, type) {
    const countdownText = this.formatCountdown(distance);
    this.updateCountdownText(message, countdownText, type);
  }

  formatCountdown(distance) {
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    let countdownText = `${hours}:${minutes}:${seconds}`;
    if (days > 0) {
      countdownText = `${days} days ` + countdownText;
    }
    return countdownText;
  }

  updateCountdownText(messagePrefix, countdownText, type) {
    const messageSpan = document.createElement("span");
    if (type === "start") {
      messageSpan.classList.add("start-class"); // Reemplaza con tus clases CSS para el inicio
    } else {
      messageSpan.classList.add("end-class"); // Reemplaza con tus clases CSS para el fin
    }
    messageSpan.innerHTML = `${messagePrefix} ${countdownText}`;

    this.element.innerHTML = ''; // Limpia el contenido existente
    this.element.appendChild(messageSpan);
  }

  updateMessage(message, type) {
    const messageSpan = document.createElement("span");
    if (type === "start") {
      messageSpan.classList.add("start-message-class"); // Clases específicas para "This auction has started"
    } else {
      messageSpan.classList.add("end-message-class"); // Clases específicas para "This auction has finished"
    }
    messageSpan.innerHTML = message;

    this.element.innerHTML = ''; // Limpia el contenido existente
    this.element.appendChild(messageSpan);
  }

  isMessageUpdateNeeded(message) {
    return !this.element.innerHTML.includes(message);
  }
}
